export const fontFamilies = {
  formal: 'Amiri, Times, serif',
  casual: 'Roboto, Arial, sans-serif',
};

export const fontSizes = [
  12,
  13,
  14, //2
  15,
  16,
  17, //5
  18,
  20, //7
  21,
  22,
  24, //10
  26,
  28,
  32, //13
  34,
  36, //15
  44,
  48,
  52,
  60,
  96, //20
];

export const textStyles = {
  jackfruit: {
    tag: 'h1',
    fontFamily: fontFamilies.formal,
    fontSize: [9, 10, 16, 20],
    fontWeight: 700,
    letterSpacing: '-1.5px',
    lineHeight: ['28px', '32px', '42px', '96px'],
  },
  watermelon: {
    tag: 'h2',
    fontFamily: fontFamilies.formal,
    fontSize: [6, 12, 19],
    fontWeight: 700,
    lineHeight: ['24px', '28px', '105.48px'],
  },
  coconut: {
    tag: 'h3',
    fontFamily: fontFamilies.formal,
    fontSize: [6, 8, 17],
    fontWeight: 700,
    lineHeight: ['20px', '26px', '84.38px'],
  },
  cabbage: {
    tag: 'h4',
    fontFamily: fontFamilies.formal,
    fontSize: [6, 10, 14],
    fontWeight: 700,
    letterSpacing: '0.25px',
    lineHeight: ['24px', '24px', '36px'],
    marginBottom: '32px',
  },
  banana: {
    tag: 'h5',
    fontFamily: fontFamilies.casual,
    fontSize: [5, 5, 10],
    fontWeight: 700,
    lineHeight: ['22px', '22px', '28.13px'],
  },
  mango: {
    tag: 'h6',
    fontFamily: fontFamilies.casual,
    fontSize: [5, 5, 7],
    fontWeight: 700,
    letterSpacing: '0.15px',
    lineHeight: ['22px', '22px', '28px'],
  },
  orange: {
    tag: 'p',
    fontFamily: fontFamilies.casual,
    fontWeight: 700,
    fontSize: [1, 1, 2],
    letterSpacing: '0.1px',
    lineHeight: ['14px', '14px', '14px'],
  },
  lemon: {
    tag: 'span',
    fontFamily: fontFamilies.casual,
    fontSize: [1, 1, 2],
    fontWeight: 500,
    letterSpacing: '0.75px',
    lineHeight: ['12px', '12px', '14px'],
  },
  jupiter: {
    tag: 'p',
    fontFamily: fontFamilies.casual,
    fontWeight: 700,
    fontSize: [3, 8, 16],
    letterSpacing: '0.5px',
    lineHeight: ['20px', '22px', '44px'],
  },
  neptune: {
    tag: 'p',
    fontFamily: fontFamilies.casual,
    fontWeight: 300,
    fontSize: [3, 5, 8, 10],
    letterSpacing: '0.5px',
    lineHeight: ['20px', '24px', '32px', '37px'],
    marginBottom: '32px',
  },
  earth: {
    tag: 'p',
    fontFamily: fontFamilies.casual,
    fontWeight: 300,
    fontSize: [2, 3, 3],
    letterSpacing: '1%',
    lineHeight: ['20px', '22px', '30px'],
  },
  venus: {
    tag: 'p',
    fontFamily: fontFamilies.casual,
    fontWeight: 400,
    fontSize: [1, 2, 2],
    letterSpacing: '0.5%',
    lineHeight: ['20px', '20px', '20px'],
  },
  mercury: {
    tag: 'p',
    fontFamily: fontFamilies.casual,
    fontWeight: 400,
    fontSize: [0, 0, 0],
    letterSpacing: '0.5px',
    lineHeight: ['12px', '12px', '12px'],
  },
  underline: {
    textDecoration: 'underline',
  },
};
