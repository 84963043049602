import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import NotFoundPage from '../modules/website/NotFoundPage';
import AdminRoute from '../routers/AdminRoute';
import { URL_STORE } from '../utils/constants';
import SuspenseFallback from './SuspenseFallback';

const Header = lazy(() => import('../modules/header-navigation/Header'));
const Footer = lazy(() => import('../modules/footer/Footer'));
const PrivateRoute = lazy(() => import('../routers/PrivateRoute'));
const PublicRoute = lazy(() => import('../routers/PublicRoute'));

const AdminSettingsPage = lazy(() =>
  import('../modules/admin/AdminSettingsPage')
);
const CategoryDetailPage = lazy(() =>
  import('../modules/admin/category/CategoryDetailPage')
);
const CategoryListPage = lazy(() =>
  import('../modules/admin/category/CategoryListPage')
);
const CouponDetailPage = lazy(() =>
  import('../modules/admin/coupon/CouponDetailPage')
);
const CouponListPage = lazy(() =>
  import('../modules/admin/coupon/CouponListPage')
);
const TailorDetailPage = lazy(() =>
  import('../modules/admin/tailor/TailorDetailPage')
);
const TailorListPage = lazy(() =>
  import('../modules/admin/tailor/TailorListPage')
);
const TailorShopDetailPage = lazy(() =>
  import('../modules/admin/tailor-shop/TailorShopDetailPage')
);
const TailorShopListPage = lazy(() =>
  import('../modules/admin/tailor-shop/TailorShopListPage')
);
const HeroCarouselItemDetailPage = lazy(() =>
  import('../modules/admin/hero-carousel-item/HeroCarouselItemDetailPage')
);
const HeroCarouselItemListPage = lazy(() =>
  import('../modules/admin/hero-carousel-item/HeroCarouselItemListPage')
);
const MeasurementGroupDetailPage = lazy(() =>
  import('../modules/admin/measurement-group/MeasurementGroupDetailPage')
);
const MeasurementGroupListPage = lazy(() =>
  import('../modules/admin/measurement-group/MeasurementGroupListPage')
);
const MeasurementQuestionDetailPage = lazy(() =>
  import('../modules/admin/measurement-question/MeasurementQuestionDetailPage')
);
const MeasurementQuestionListPage = lazy(() =>
  import('../modules/admin/measurement-question/MeasurementQuestionListPage')
);
const MillDetailPage = lazy(() =>
  import('../modules/admin/mill/MillDetailPage')
);
const MillListPage = lazy(() => import('../modules/admin/mill/MillListPage'));
const FabricDetailPage = lazy(() =>
  import('../modules/admin/fabric/FabricDetailPage')
);
const FabricListPage = lazy(() =>
  import('../modules/admin/fabric/FabricListPage')
);
const ProductDetailPage = lazy(() =>
  import('../modules/admin/product/ProductDetailPage')
);
const ResetPasswordPage = lazy(() =>
  import('../modules/auth/local-auth/ResetPasswordPage')
);
const StoreProductDetailPage = lazy(() =>
  import('../modules/store/ProductDetailPage')
);

const AboutUsPage = lazy(() => import('../modules/website/AboutUsPage'));
const FAQPage = lazy(() => import('../modules/website/faq/FAQPage'));
const PrivacyPolicyPage = lazy(() =>
  import('../modules/website/PrivacyPolicyPage')
);
const ColorGeniePrivacyPolicyPage = lazy(() =>
  import('../modules/website/ColorGeniePrivacyPolicyPage')
);
const ProductListPage = lazy(() =>
  import('../modules/admin/product/ProductListPage')
);
const TermsOfServicePage = lazy(() =>
  import('../modules/website/TermsOfServicePage')
);
const ColorGeniePublicPage = lazy(() =>
  import('../modules/website/color-genie/ColorGeniePublicPage')
);
const GeniePublicPage = lazy(() =>
  import('../modules/website/GeniePublicPage')
);

const ChummaPage = lazy(() => import('./ChummaPage'));
const ContactUsPage = lazy(() =>
  import('../modules/website/contact-us/ContactUsPage')
);
const HomePage = lazy(() => import('../modules/website/HomePage'));
const CartPage = lazy(() => import('../modules/store/cart/CartPage'));
const AddressPage = lazy(() =>
  import('../modules/store/checkout/address/AddressPage')
);
const PaymentPage = lazy(() =>
  import('../modules/store/checkout/payment/PaymentPage')
);

const OrderConfirmationPage = lazy(() =>
  import('../modules/store/checkout/order-confirmation/OrderConfirmationPage')
);
const StorefrontPage = lazy(() =>
  import('../modules/store/storefront/StorefrontPage')
);
const FabricsStorefrontPage = lazy(() =>
  import('../modules/store/storefront/GenericStorefrontPage')
);
const GarmentsStorefrontPage = lazy(() =>
  import('../modules/store/storefront/GarmentsStorefrontPage')
);
const CustomizeProductPage = lazy(() =>
  import('../modules/store/customize-product/CustomizeProductPage')
);
const LoginRegisterPage = lazy(() =>
  import('../modules/auth/LoginRegisterPage')
);

const BrowserRoutes = ({ history }) => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Header />
      <Switch>
        <PublicRoute restricted={false} exact path='/' component={HomePage} />
        <PublicRoute
          restricted={false}
          exact
          path='/login'
          component={LoginRegisterPage}
        />
        <PublicRoute
          restricted={false}
          path='/reset-password/:token/:userId'
          component={ResetPasswordPage}
        />
        <PublicRoute restricted={false} path='/chumma' component={ChummaPage} />
        <PublicRoute restricted={false} path='/cc' component={ChummaPage} />
        <PublicRoute
          restricted={false}
          path='/contact-us'
          component={ContactUsPage}
        />
        <PublicRoute
          restricted={false}
          path={URL_STORE}
          component={StorefrontPage}
          exact
        />
        <PublicRoute
          restricted={false}
          path={`${URL_STORE}/fabrics`}
          component={FabricsStorefrontPage}
          exact
        />
        <PublicRoute
          restricted={false}
          path={`${URL_STORE}/bespoke`}
          component={GarmentsStorefrontPage}
          exact
        />
        <PublicRoute
          restricted={false}
          path='/cart/edit/:cartIndex'
          component={CustomizeProductPage}
        />
        <PublicRoute
          restricted={false}
          path={`${URL_STORE}/:categorySlug/:productSlug/customize`}
          component={CustomizeProductPage}
        />
        <PublicRoute
          restricted={false}
          path={`${URL_STORE}/:categorySlug/:productSlug`}
          component={StoreProductDetailPage}
        />
        <PublicRoute restricted={false} path='/cart' component={CartPage} />
        <PublicRoute
          restricted={false}
          path='/checkout/address'
          component={AddressPage}
        />
        <PublicRoute
          restricted={false}
          path='/checkout/payment'
          component={PaymentPage}
        />
        <PublicRoute
          restricted={false}
          path='/checkout/thank-you'
          component={OrderConfirmationPage}
        />
        <PublicRoute restricted={false} path='/faq' component={FAQPage} />
        <PublicRoute
          restricted={false}
          path='/about-us'
          component={AboutUsPage}
        />
        <PublicRoute
          restricted={false}
          path='/privacy-policy/color-genie'
          component={ColorGeniePrivacyPolicyPage}
        />
        <PublicRoute
          restricted={false}
          path='/privacy-policy'
          component={PrivacyPolicyPage}
        />
        <PublicRoute
          restricted={false}
          path='/color-genie'
          component={ColorGeniePublicPage}
        />
        <PublicRoute
          restricted={false}
          path='/genie'
          component={GeniePublicPage}
        />
        <PublicRoute
          restricted={false}
          path='/terms-of-service'
          component={TermsOfServicePage}
        />
        <AdminRoute
          path='/admin/categories/add'
          component={CategoryDetailPage}
        />
        <AdminRoute
          path='/admin/categories/:id'
          component={CategoryDetailPage}
        />
        <AdminRoute path='/admin/categories' component={CategoryListPage} />
        <AdminRoute path='/admin/tailors/add' component={TailorDetailPage} />
        <AdminRoute path='/admin/tailors/:id' component={TailorDetailPage} />
        <AdminRoute path='/admin/tailors' component={TailorListPage} />
        <AdminRoute
          path='/admin/tailor-shops/add'
          component={TailorShopDetailPage}
        />
        <AdminRoute
          path='/admin/tailor-shops/:id'
          component={TailorShopDetailPage}
        />
        <AdminRoute path='/admin/tailor-shops' component={TailorShopListPage} />
        <AdminRoute
          path='/admin/hero-carousel-items/add'
          component={HeroCarouselItemDetailPage}
        />
        <AdminRoute
          path='/admin/hero-carousel-items/:id'
          component={HeroCarouselItemDetailPage}
        />
        <AdminRoute
          path='/admin/hero-carousel-items'
          component={HeroCarouselItemListPage}
        />
        <AdminRoute
          path='/admin/measurement-groups/add'
          component={MeasurementGroupDetailPage}
        />
        <AdminRoute
          path='/admin/measurement-groups/:id'
          component={MeasurementGroupDetailPage}
        />
        <AdminRoute
          path='/admin/measurement-groups'
          component={MeasurementGroupListPage}
        />
        <AdminRoute
          path='/admin/measurement-questions/add'
          component={MeasurementQuestionDetailPage}
        />
        <AdminRoute
          path='/admin/measurement-questions/:id'
          component={MeasurementQuestionDetailPage}
        />
        <AdminRoute
          path='/admin/measurement-questions'
          component={MeasurementQuestionListPage}
        />
        <AdminRoute path='/admin/mills/add' component={MillDetailPage} />
        <AdminRoute path='/admin/mills/:id' component={MillDetailPage} />
        <AdminRoute path='/admin/mills' component={MillListPage} />
        <AdminRoute path='/admin/fabrics/add' component={FabricDetailPage} />
        <AdminRoute path='/admin/fabrics/:id' component={FabricDetailPage} />
        <AdminRoute path='/admin/fabrics' component={FabricListPage} />
        <AdminRoute path='/admin/products/add' component={ProductDetailPage} />
        <AdminRoute path='/admin/products/:id' component={ProductDetailPage} />
        <AdminRoute path='/admin/products' component={ProductListPage} />
        <AdminRoute path='/admin/coupons/add' component={CouponDetailPage} />
        <AdminRoute path='/admin/coupons/:id' component={CouponDetailPage} />
        <AdminRoute path='/admin/coupons' component={CouponListPage} />
        <AdminRoute path='/admin' component={AdminSettingsPage} />
        <PublicRoute restricted={false} component={NotFoundPage} />
      </Switch>

      <Footer />
    </Suspense>
  );
};

export default BrowserRoutes;
